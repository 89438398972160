import { TOKEN, apiUrl } from '../../config';
const endpoint = 'chatInformation';
const apiUrlWithEndpoint = apiUrl(endpoint);


export default {
    async postDataInformation(data) {
        try {
            const response = await fetch(apiUrlWithEndpoint, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': TOKEN,
              },
              body: JSON.stringify(data),
            });
          
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
          
            const responseData = await response.json();
            return responseData;
          } catch (error) {
            console.error('Error al realizar la solicitud:', error);
            throw error;
          }
    }
}