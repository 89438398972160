<template>
  <div class="body-chat-container">
    <ChatContainer
      :chatInformation="chatInformationResponse"
      v-if="chatInformationResponse"
    ></ChatContainer>
    <div v-else>
      <!-- Contenido de carga (loading) centrado -->
      <div class="loading-container">{{ textInfo }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import ChatContainer from "./components/ChatContainer.vue";
import serviceChatInformation from "./services/ServicesChatInformation";
const chatInformationResponse = ref(null);
let textInfo = ref("Cargando...");
import { COMPANY, CHANNEL, DEALERPHONECHANNEL } from "../config";
import { useCookies } from "vue3-cookies";
const cookies = useCookies();

onMounted(async () => {
  // Obtener la cadena de consulta de la URL
  const queryString = window.location.search;
  //const queryString = `?PHONE=%2B56920838610&RUT=19572399-0&OPPORTUNITYID=123456`;

  // usuario y branchSelected desde cookies
  const user = cookies.cookies.get("user");
  const branchSelected = cookies.cookies.get("branchSelected");

  // Parsear la cadena de consulta en un objeto de parámetros
  const urlParams = new URLSearchParams(queryString);

  // Obtener los valores de los parámetros PHONE, RUT y OPPORTUNITYID
  const PHONE = decodeURIComponent(
    urlParams.get("PHONE") ? urlParams.get("PHONE") : ""
  );
  const RUT = decodeURIComponent(
    urlParams.get("RUT") ? urlParams.get("RUT") : ""
  );
  const OPPORTUNITYID = decodeURIComponent(
    urlParams.get("OPPORTUNITYID") ? urlParams.get("OPPORTUNITYID") : ""
  );
  const FIRSTNAME = decodeURIComponent(
    urlParams.get("FIRSTNAME") ? urlParams.get("FIRSTNAME") : ""
  );
  const LASTNAME = decodeURIComponent(
    urlParams.get("LASTNAME") ? urlParams.get("LASTNAME") : ""
  );

  // Verificar si los parámetros existen y realizar la solicitud
  if (PHONE && RUT && user?.id && branchSelected?.branch) {
    chatInformationResponse.value =
      await serviceChatInformation.postDataInformation({
        phone: PHONE,
        rut: RUT,
        //channel: CHANNEL,
        phoneDealer: branchSelected?.branch?.dealer?.phoneWhatsapp
          ? branchSelected.branch.dealer.phoneWhatsapp
          : DEALERPHONECHANNEL,
        company: COMPANY,
        executive: user.id,
        executiveEmail: user.mail,
        additional_data: {
          oportunidad: OPPORTUNITYID,
          branchId: branchSelected?.branch?._id,
          quoteId: "",
          collaboratorId: user?.idColaborator,
          interactionTypeId: branchSelected?.branch?.interactionTypeId,
          IdSucursal: branchSelected?.branch?.IdSucursal,
          fantasyName: branchSelected?.branch?.fantasyName,
          executiveEmail: user.mail,
          rut: user.rut,
          firstName: user.firstName,
          lastName: user.lastName,
          position: user.position,
          roleId: user.roleId,
          dealerId:branchSelected?.branch?.dealer?._id,
          IdConcesionario:branchSelected?.branch?.dealer?.IdConcesionario,
          dealerAlias:branchSelected?.branch?.dealer?.alias,
          phoneWhatsapp:branchSelected?.branch?.dealer?.phoneWhatsapp,
          nombreCliente:FIRSTNAME,
          apellidoCliente:LASTNAME
        },
      });
  } else {
    console.error("Faltan parámetros en la cadena de consulta.");
    textInfo.value = "Error: Faltan parámetros en la cadena de consulta";
  }
});
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  font-weight: normal;
}

.body-chat-container {
  /*color: var(--color-text);*/
  min-height: 100vh;
  background: var(--color-background);
  transition: color 0.5s, background-color 0.5s;
  line-height: 1.6;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-gif {
  width: 100px;
  height: auto;
}
</style>
