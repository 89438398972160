<script setup>
import { ref, defineProps } from "vue";
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});
const currentLayout = ref({});
const indexLayout = ref(0)
currentLayout.value = props.data.content[0]
function leftControl() {
    indexLayout.value = indexLayout.value - 1
    if (indexLayout.value <= 0) indexLayout.value = 0
    currentLayout.value = props.data.content[indexLayout.value]
}
 function rightControl() {
    indexLayout.value = indexLayout.value + 1
    if (indexLayout.value >= props.data.content.length) indexLayout.value = props.data.content.length - 1
    currentLayout.value = props.data.content[indexLayout.value]
 }
</script>
<template>
  <div
    v-if="data.content && data.content.length > 0"
    class="app-buttonslider-container"
  >
    <div class="app-left-controller-slider" @click="leftControl()">
      <img src="../assets/icons/left-arrow.png" />
    </div>
    <div class="app-right-controller-slider" @click="rightControl()">
      <img src="../assets/icons/right-arrow.png" />
    </div>
    <div>
      <div v-if="currentLayout?.image" class="app-chat-button-image-container">
        <img :src="currentLayout.image" class="app-chat-button-image" />
      </div>
      <p v-if="currentLayout?.title" class="app-chat-button-title">
        {{ currentLayout.title }}
      </p>
      <p v-if="currentLayout?.text">{{ currentLayout.text }}</p>
      <div
        v-if="currentLayout?.buttons && currentLayout?.buttons.length > 0"
        class="app-chat-button-container"
      >
        <div
          v-for="(option, index) in currentLayout.buttons"
          :key="index"
          class="app-chat-button-option"
        >
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.app-buttonslider-container {
  position: relative;
}
.app-chat-button-container {
  margin: 0.8rem;
  border-radius: 20px;
  background-color: #515a63;
}
.app-chat-button-option {
  color: white;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid white;
  cursor: default;
  font-size: 12px;
}

.app-chat-button-option:first-child {
  border-radius: 20px 20px 0px 0px;
}

.app-chat-button-option:last-child {
  border-radius: 0px 0px 20px 20px;
  border-bottom: unset;
}

.app-chat-button-image-container {
  margin: auto;
  max-width: 300px;
  height: auto;
}

.app-chat-button-image {
  width: 100%;
  height: 100%;
}

.app-chat-button-title {
  margin-bottom: 0.7rem;
  margin-top: 0.7rem;
}

.app-left-controller-slider,
.app-right-controller-slider {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: #515a63;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
}

.app-left-controller-slider img,
.app-right-controller-slider img {
  margin: auto;
}

.app-left-controller-slider {
  top: 20%;
  left: 0;
}
.app-right-controller-slider {
  top: 20%;
  right: 0;
}
</style>
