export const PHONE = '+56920838610';
export const RUT = '19572399-0';
export const COMPANY = 'kia';
export const EXECUTIVE = '11166059';
export const CONVERSATION = 'abc123';
export const CHANNEL = '62d03ccefa55c70131b347e0';
export const DEALERPHONECHANNEL = '+56965194007';
export const CHATUSER = '6512fafac8bfaaecfd6a57a1';
export const AUTH = '$chatbot$';
export const EVENT_SOURCE_URL = 'https://single-chat-chatbot-indumotora.gatblac.com/api/singlechatpri/';
export const TOKEN = 'QivwNOuWkTI8PJGfB!sc03EQg!bJ8u0d-tFxATQ/qzNqjb8iW1viBd6eFFP1hSh=fZhyR0C0VT9fnKwjC9iEMo3XSApdm6Xd1oeRjzHO4qZ8sCDLRIa86wcbiARMI1?-vAi=o4nUFQf?JZKUI0VTHnO4Knflds2StYXewxHtPpMCi2mExrNMCt?e1wGlHb9t6eRbf?kYA0hSUybGJOfnhSe7LSZ4Mywd5iQx9XXO1eHmxy2E0-A8mUHX-uw=k5Yr';
export const apiUrl = (endpoint) => `https://api-public-chatbot-indumotora.gatblac.com/api/singlechatpri/${endpoint}`;
